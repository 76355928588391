// eslint-disable

export default {
  '2--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '2--instructions1.content--b6292da9e967f6fb0d4c77f9c429199e.webm',
    },
  ],
  '2--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '2--instructions1.modal.content--84c1ea5f1c69c116e6e9c0b65466ff2a.webm',
    },
  ],
  '2--instructions2.content': [
    {
      tags: [],
      noTags: ['diagonals', 'phoneticHelp', 'semanticHelp'],
      fname: '2--instructions2.content--b352a7474f654a4cb63df400f5d6205a.webm',
    },
    {
      tags: ['semanticHelp'],
      noTags: ['diagonals', 'phoneticHelp'],
      fname: '2--instructions2.content--98cd00f6291dff00f1984bae87bdeccc.webm',
    },
    {
      tags: ['phoneticHelp'],
      noTags: ['diagonals', 'semanticHelp'],
      fname: '2--instructions2.content--774b2c7a57a481937a4d2ece22099462.webm',
    },
    {
      tags: ['phoneticHelp', 'semanticHelp'],
      noTags: ['diagonals'],
      fname: '2--instructions2.content--e43a447d7c38df2cdb431cde3ae4f36d.webm',
    },
    {
      tags: ['diagonals'],
      noTags: ['phoneticHelp', 'semanticHelp'],
      fname: '2--instructions2.content--c7b9ab7ae9d79440f3759076d8008263.webm',
    },
    {
      tags: ['diagonals', 'semanticHelp'],
      noTags: ['phoneticHelp'],
      fname: '2--instructions2.content--e051f1d4ab528e3f5d4c5575302f2886.webm',
    },
    {
      tags: ['diagonals', 'phoneticHelp'],
      noTags: ['semanticHelp'],
      fname: '2--instructions2.content--d279f9af8b3a3a45f6e9b9438406a60e.webm',
    },
    {
      tags: ['diagonals', 'phoneticHelp', 'semanticHelp'],
      noTags: [],
      fname: '2--instructions2.content--e5c65babd9da839b9818bfc5e6ae1939.webm',
    },
  ],
  '11--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '11--instructions1.content--2ab0ae6c3473425affbd4d91ea084c1b.webm',
    },
  ],
  '11--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '11--instructions1.modal.content--c2e0e4a3b8a7c3d908a5943170b3496c.webm',
    },
  ],
  '11--instructions2.content': [
    {
      tags: [],
      noTags: ['numbers', 'letters', 'words', 'hide'],
      fname: '11--instructions2.content--a74d1d26d77ba1f6deb1d74f5f5cece7.webm',
    },
    {
      tags: ['hide'],
      noTags: ['numbers', 'letters', 'words'],
      fname: '11--instructions2.content--1703db6b5df14762300723d6ebb43395.webm',
    },
    {
      tags: ['words'],
      noTags: ['numbers', 'letters', 'hide'],
      fname: '11--instructions2.content--c1a04f02283a3d44c6a75b679422cc9a.webm',
    },
    {
      tags: ['words', 'hide'],
      noTags: ['numbers', 'letters'],
      fname: '11--instructions2.content--bf60b41d709820e0b1600355b0282ca2.webm',
    },
    {
      tags: ['letters'],
      noTags: ['numbers', 'words', 'hide'],
      fname: '11--instructions2.content--a74d1d26d77ba1f6deb1d74f5f5cece7.webm',
    },
    {
      tags: ['letters', 'hide'],
      noTags: ['numbers', 'words'],
      fname: '11--instructions2.content--1703db6b5df14762300723d6ebb43395.webm',
    },
    {
      tags: ['letters', 'words'],
      noTags: ['numbers', 'hide'],
      fname: '11--instructions2.content--c1a04f02283a3d44c6a75b679422cc9a.webm',
    },
    {
      tags: ['letters', 'words', 'hide'],
      noTags: ['numbers'],
      fname: '11--instructions2.content--bf60b41d709820e0b1600355b0282ca2.webm',
    },
    {
      tags: ['numbers'],
      noTags: ['letters', 'words', 'hide'],
      fname: '11--instructions2.content--df4a81d07107573302f5737c11959711.webm',
    },
    {
      tags: ['numbers', 'hide'],
      noTags: ['letters', 'words'],
      fname: '11--instructions2.content--931d44fb3bb40b7ab45966eed8097e4c.webm',
    },
    {
      tags: ['numbers', 'words'],
      noTags: ['letters', 'hide'],
      fname: '11--instructions2.content--9a287bbb345476569bad68d57aceb4e1.webm',
    },
    {
      tags: ['numbers', 'words', 'hide'],
      noTags: ['letters'],
      fname: '11--instructions2.content--704cf9104182f233e8f2621fe2f6cc5b.webm',
    },
    {
      tags: ['numbers', 'letters'],
      noTags: ['words', 'hide'],
      fname: '11--instructions2.content--b9d326c6101bbcc56466c41aefa4bb1e.webm',
    },
    {
      tags: ['numbers', 'letters', 'hide'],
      noTags: ['words'],
      fname: '11--instructions2.content--7dff9c1ff3c9fd1e3902bddd3c2092c0.webm',
    },
    {
      tags: ['numbers', 'letters', 'words'],
      noTags: ['hide'],
      fname: '11--instructions2.content--be4b590b1369cb1ac19ede7b605937d8.webm',
    },
    {
      tags: ['numbers', 'letters', 'words', 'hide'],
      noTags: [],
      fname: '11--instructions2.content--652234539ab613352f677681be171d0d.webm',
    },
  ],
  '49--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '49--instructions1.content--5d1465ed95777cdd393ade826d126179.webm',
    },
  ],
  '49--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '49--instructions1.modal.content--f58a3f40994a80278f5219cc9ee836a8.webm',
    },
  ],
  '49--instructions2.content': [
    {
      tags: [],
      noTags: ['physical', 'mental'],
      fname: '49--instructions2.content--42525300fce18bc0bb6747fed96d8523.webm',
    },
    {
      tags: ['mental'],
      noTags: ['physical'],
      fname: '49--instructions2.content--54dd7124b2aab2ed47f2f584a4562f12.webm',
    },
    {
      tags: ['physical'],
      noTags: ['mental'],
      fname: '49--instructions2.content--f810ee1b80053f35c172ff82f65e7a26.webm',
    },
    {
      tags: ['physical', 'mental'],
      noTags: [],
      fname: '49--instructions2.content--3c2fdd2f2c894d30bb0a9d3280d0fb5e.webm',
    },
  ],
  '106--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '106--instructions1.content--b776d4a1f3d6f0a6adfa498793e5ce69.webm',
    },
  ],
  '106--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '106--instructions1.modal.content--71e4fa5a77d5fcf1b390b6ae1835c3a3.webm',
    },
  ],
  '106--instructions2.content': [
    {
      tags: [],
      noTags: ['whereMode', 'whatMode'],
      fname: '106--instructions2.content--7ed8b5bb920e51b9f34c9f73262623fe.webm',
    },
    {
      tags: ['whatMode'],
      noTags: ['whereMode'],
      fname: '106--instructions2.content--83f4523a88f975e83addf98423a145e3.webm',
    },
    {
      tags: ['whereMode'],
      noTags: ['whatMode'],
      fname: '106--instructions2.content--2373dc4ffd518868b6ed3f43e2c59f44.webm',
    },
    {
      tags: ['whereMode', 'whatMode'],
      noTags: [],
      fname: '106--instructions2.content--f4acf278c68a9f432176ad190d123c70.webm',
    },
  ],
  '108--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '108--instructions1.content--19b302efc1dd93a754b64df56adf2f33.webm',
    },
  ],
  '108--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '108--instructions1.modal.content--c4ce33f071d77e5a43f8eed18ee0a839.webm',
    },
  ],
  '108--instructions2.content': [
    {
      tags: null,
      noTags: null,
      fname: '108--instructions2.content--c639df8c35ea23773ca993d4fbb86b22.webm',
    },
  ],
  '128--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '128--instructions1.content--44596ec953dcd12feca5c68a1f833806.webm',
    },
  ],
  '128--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '128--instructions1.modal.content--cb7b9b688398c7234b7b56a36d2d6341.webm',
    },
  ],
  '128--instructions2.content': [
    {
      tags: [],
      noTags: ['symbols', 'letters', 'multi', 'ornot'],
      fname: '128--instructions2.content--bc05ab6a9b8b607eff1cd2c0286fc33e.webm',
    },
    {
      tags: ['ornot'],
      noTags: ['symbols', 'letters', 'multi'],
      fname: '128--instructions2.content--9f2e33ce0fc2f5b521e527ee08b48b93.webm',
    },
    {
      tags: ['multi'],
      noTags: ['symbols', 'letters', 'ornot'],
      fname: '128--instructions2.content--ef1668439f9e4f57dcf7c7961763b091.webm',
    },
    {
      tags: ['multi', 'ornot'],
      noTags: ['symbols', 'letters'],
      fname: '128--instructions2.content--bdb8f7659d5badc1461988b91db70331.webm',
    },
    {
      tags: ['letters'],
      noTags: ['symbols', 'multi', 'ornot'],
      fname: '128--instructions2.content--baf5a0315d55f6c3ff5cac339b0d26fc.webm',
    },
    {
      tags: ['letters', 'ornot'],
      noTags: ['symbols', 'multi'],
      fname: '128--instructions2.content--89920ba0ebe1affb7c44ecff9a233ec8.webm',
    },
    {
      tags: ['letters', 'multi'],
      noTags: ['symbols', 'ornot'],
      fname: '128--instructions2.content--39d5eacc4651d73f45d844c7a124c4ac.webm',
    },
    {
      tags: ['letters', 'multi', 'ornot'],
      noTags: ['symbols'],
      fname: '128--instructions2.content--f652384da121fe38323b7dca5b7a9892.webm',
    },
    {
      tags: ['symbols'],
      noTags: ['letters', 'multi', 'ornot'],
      fname: '128--instructions2.content--9378d27eebc5812d76c86450c85fdd5c.webm',
    },
    {
      tags: ['symbols', 'ornot'],
      noTags: ['letters', 'multi'],
      fname: '128--instructions2.content--ff0c1840474caeaf4bcb5056af3af3c3.webm',
    },
    {
      tags: ['symbols', 'multi'],
      noTags: ['letters', 'ornot'],
      fname: '128--instructions2.content--05032c8c1b0363547e3a5365778f2f2b.webm',
    },
    {
      tags: ['symbols', 'multi', 'ornot'],
      noTags: ['letters'],
      fname: '128--instructions2.content--aecc50b4176a78c5fca4bb2d7d9a7a5e.webm',
    },
    {
      tags: ['symbols', 'letters'],
      noTags: ['multi', 'ornot'],
      fname: '128--instructions2.content--7dbf5fb7645d905e9791544bc19367f0.webm',
    },
    {
      tags: ['symbols', 'letters', 'ornot'],
      noTags: ['multi'],
      fname: '128--instructions2.content--ba8b193a756dfb05b6bd53d5e986cbe0.webm',
    },
    {
      tags: ['symbols', 'letters', 'multi'],
      noTags: ['ornot'],
      fname: '128--instructions2.content--5485aa2f94c1a544393a87ee9d649b6c.webm',
    },
    {
      tags: ['symbols', 'letters', 'multi', 'ornot'],
      noTags: [],
      fname: '128--instructions2.content--cbc3ae68e185c64aca9e5d2d1d3082e9.webm',
    },
  ],
  '165--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '165--instructions1.content--89d6247d4311dc68c3fe3efde8821860.webm',
    },
  ],
  '165--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '165--instructions1.modal.content--c4ce33f071d77e5a43f8eed18ee0a839.webm',
    },
  ],
  '165--instructions2.content': [
    {
      tags: [],
      noTags: ['guessImg', 'guessName', 'guessCityAndName', 'hasIntruders'],
      fname: '165--instructions2.content--add7521e0ca458a6a65a06d5c7a3ff0c.webm',
    },
    {
      tags: ['hasIntruders'],
      noTags: ['guessImg', 'guessName', 'guessCityAndName'],
      fname: '165--instructions2.content--9e58913beaeda85ff5d298acc6be0bec.webm',
    },
    {
      tags: ['guessCityAndName'],
      noTags: ['guessImg', 'guessName', 'hasIntruders'],
      fname: '165--instructions2.content--6b80a058b5d41ee9542503266b0dff67.webm',
    },
    {
      tags: ['guessCityAndName', 'hasIntruders'],
      noTags: ['guessImg', 'guessName'],
      fname: '165--instructions2.content--aed730440dcaf14908348b65963f0084.webm',
    },
    {
      tags: ['guessName'],
      noTags: ['guessImg', 'guessCityAndName', 'hasIntruders'],
      fname: '165--instructions2.content--b74a7d2b9a5574a5031451c34e9c8de7.webm',
    },
    {
      tags: ['guessName', 'hasIntruders'],
      noTags: ['guessImg', 'guessCityAndName'],
      fname: '165--instructions2.content--38b4a623d4f08d289e31f851263e9038.webm',
    },
    {
      tags: ['guessName', 'guessCityAndName'],
      noTags: ['guessImg', 'hasIntruders'],
      fname: '165--instructions2.content--bcafc1af51defca8fe7bb3e4184faf94.webm',
    },
    {
      tags: ['guessName', 'guessCityAndName', 'hasIntruders'],
      noTags: ['guessImg'],
      fname: '165--instructions2.content--0c63ef2d9e164f8ca966273e8f0259ce.webm',
    },
    {
      tags: ['guessImg'],
      noTags: ['guessName', 'guessCityAndName', 'hasIntruders'],
      fname: '165--instructions2.content--df44638138e733ca5783fcc2c8352380.webm',
    },
    {
      tags: ['guessImg', 'hasIntruders'],
      noTags: ['guessName', 'guessCityAndName'],
      fname: '165--instructions2.content--ed90d3c420b46aad536b252eb77dec32.webm',
    },
    {
      tags: ['guessImg', 'guessCityAndName'],
      noTags: ['guessName', 'hasIntruders'],
      fname: '165--instructions2.content--4f904ee804797eb34fe55defaf393c2e.webm',
    },
    {
      tags: ['guessImg', 'guessCityAndName', 'hasIntruders'],
      noTags: ['guessName'],
      fname: '165--instructions2.content--a86a72ab9c3e107525d21d04d12147b0.webm',
    },
    {
      tags: ['guessImg', 'guessName'],
      noTags: ['guessCityAndName', 'hasIntruders'],
      fname: '165--instructions2.content--6347634fd6ae9d55e171449303eddaec.webm',
    },
    {
      tags: ['guessImg', 'guessName', 'hasIntruders'],
      noTags: ['guessCityAndName'],
      fname: '165--instructions2.content--7309daa7c51aa405282fc1e6f356c546.webm',
    },
    {
      tags: ['guessImg', 'guessName', 'guessCityAndName'],
      noTags: ['hasIntruders'],
      fname: '165--instructions2.content--0bc044755ed2557fe3390ab0d96964e6.webm',
    },
    {
      tags: ['guessImg', 'guessName', 'guessCityAndName', 'hasIntruders'],
      noTags: [],
      fname: '165--instructions2.content--a428d12f3de4a6e414d9d2058f5bddac.webm',
    },
  ],
  '342--instructions1.content': [
    {
      tags: null,
      noTags: null,
      fname: '342--instructions1.content--57d34632954eef96934e147cdab54f5e.webm',
    },
  ],
  '342--instructions1.modal.content': [
    {
      tags: null,
      noTags: null,
      fname: '342--instructions1.modal.content--5148bbdbea7b86138cab7672bd3a5013.webm',
    },
  ],
  '342--instructions2.content': [
    {
      tags: null,
      noTags: null,
      fname: '342--instructions2.content--b74d01ef157614546bfae9015bbf0089.webm',
    },
  ],
};
