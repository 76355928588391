import { BrowserTracing } from '@sentry/react';
import * as Sentry from '@sentry/react';

const env = import.meta.env as ImportMetaEnv & {
  VITE_HM_ENV: 'production' | 'development' | 'staging' | undefined;
};

Sentry.init({
  dsn: 'https://f07e4f4cdd394b52ae83638708e2cfba@o4504085718761472.ingest.sentry.io/4504956375990272',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Custom config
  release: '__APP_VERSION__',
  environment: env.VITE_HM_ENV ?? 'local',
});

Sentry.setTag('framework_version', '__APP_FRAMEWORK_VERSION__');

export default Sentry;
