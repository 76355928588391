import { RunEnv } from '@sbthuman/env-pricpaca';
import { GameReportsBoundaries } from '@sbthuman/game-reports/index.es';
import React, { useEffect, useMemo, useState } from 'react';
import Sentry from './scripts/sentry';
import tts from './ttsManifest';

const env = (import.meta.env as ImportMetaEnv & { VITE_HM_ENV: 'production' | 'development' | 'staging' | undefined })
  .VITE_HM_ENV;
const mode = import.meta.env.MODE as 'development' | 'production';
const qs = new window.URLSearchParams(window.location.search);
const VALID_DEVTOKEN = env !== 'production' ? 'wm2yxeagbx' : 'UNUSED';

let reportSent = false;

const App: React.FC = () => {
  const [devToken, setDevToken] = useState<string | null>(null);
  const applyDevToken = env !== 'production' && qs.get('token') === VALID_DEVTOKEN;

  useEffect(() => {
    (async () => {
      if (applyDevToken) {
        setDevToken(await (await fetch('https://hm-he-token.dev.parapluie.org/')).text());
      }
    })();
  });

  const inputs = useMemo(() => {
    const rawDuration = qs.get('duration');
    const durationUnit = qs.get('durationUnit') ?? 'm';
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const duration = +rawDuration! * (durationUnit === 'm' ? 60 : 1);
    return {
      token: devToken ?? qs.get('token'),
      duration,
      taskId: qs.get('taskId'),
      contextId: qs.get('contextId'),
    };
  }, [devToken]);

  if (applyDevToken && devToken === null) {
    return <div>Fetching dev token</div>;
  }

  let urlPrefix = 'dev.';
  if (env === 'staging') {
    urlPrefix = 'staging.';
  } else if (env === 'production') {
    urlPrefix = '';
  }

  return (
    <GameReportsBoundaries
      reportHandler={(r: unknown) => {
        if (!reportSent) {
          Sentry.withScope((scope) => {
            scope.addAttachment({
              filename: 'report.json',
              data: JSON.stringify(r),
            });
            Sentry.captureException(new Error('Manual report sent'));
            // eslint-disable-next-line no-alert
            alert('Rapport OK');
          });
          reportSent = true;
        } else {
          // eslint-disable-next-line no-alert
          alert('Pas de rapport');
        }
      }}
    >
      <RunEnv
        mode={mode}
        domain={`https://api.${urlPrefix}work.activities.humansmatter.co/`}
        tts={tts}
        inputs={inputs}
        onFatalError={(e: Error) => Sentry.captureException(e)}
      />
    </GameReportsBoundaries>
  );
};

export default App;
